import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";
import { Button } from "@progress/kendo-react-buttons";
import { Loader, ErrorFallback } from "smart-react";
import "./Editor.scss";

/**
 * Editor core component
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The Editor component.
 */
const Editor = ({ editorData, setEditorData }) => {
  /**
   * editor value change
   */
  const onChange = React.useCallback((val, viewUpdate) => {
    const editorOBj = editorData;
    editorOBj.value = val;
    setEditorData({ editorOBj, isClose: false });
  }, []);
  /**
   * close the editor screen
   */
  const close = () => {
    setEditorData({ editorOBj: editorData, isClose: true });
  };
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <div className="container mx-auto">
        <React.Suspense fallback={<Loader />}>
          <div className="k-border k-m-3 k-p-3 command-syntax-content">
            <CodeMirror
              value={editorData?.value ?? ""}
              height="70vh"
              extensions={[javascript({ jsx: true })]}
              onChange={onChange}
            />
            <div className="lcs-action">
              <Button themeColor={"primary"} onClick={() => close()}>
                Close
              </Button>
            </div>
          </div>
        </React.Suspense>
      </div>
    </ErrorBoundary>
  );
};
export default Editor;
