import React from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";

/**
 * DropdownList
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The DropdownList.
 */
const DropdownList = ({
  tenantList,
  label,
  defaultItem,
  handleChange,
  ...others
}) => {
  const [filteredTenantList, setFilteredTenantList] =
    React.useState(tenantList);

  const filterData = (filter) => {
    const data = tenantList.slice();
    return filterBy(data, filter);
  };

  const filterChange = (event) => {
    setFilteredTenantList(filterData(event.filter));
  };

  React.useEffect(() => {
    setFilteredTenantList(tenantList);
  }, [tenantList]);

  return (
    <>
      <DropDownList
        data={filteredTenantList}
        filterable={true}
        onFilterChange={filterChange}
        onChange={handleChange}
        defaultItem={defaultItem}
        {...others}
      />
    </>
  );
};

export default DropdownList;
