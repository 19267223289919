import React from "react";
import { DropDownInput } from "smart-react";
import {
  getAuthorizedTenants,
  findTenantById,
} from "../../../../Utils/Auth/ValidatePermissions";
/**
 * TenantDropdown component for showing dropdown data on add screens
 */
const TenantDropdown = ({
  textField,
  label,
  className,
  dataItemKey,
  tenant,
  defaultItem,
  tenantFormId,
  tenantFormName,
  setFormData,
  setTenant,
  editMode,
  filterable,
  requiredField,
}) => {
  const tenantList = getAuthorizedTenants();
  /**
   * handle dropdown Change
   * @e object
   */
  const handleDropdownChange = (e) => {
    let tempTenant = findTenantById(e?.value?.TenantId);
    setTenant(tempTenant);
    setFormData((prevState) => ({
      ...prevState,
      [tenantFormId]: e.value.TenantId ? e.value.TenantId : "",
      [tenantFormName]: tempTenant?.TenantName ? tempTenant?.TenantName : "",
    }));
  };
  return (
    <DropDownInput
      textField={textField}
      label={label}
      dataItemKey={dataItemKey}
      className={className}
      dataSet={tenantList}
      value={tenant}
      defaultItem={defaultItem}
      renderTypes={editMode}
      handleChange={handleDropdownChange}
      filterable={filterable}
      requiredField={requiredField}
    />
  );
};

export default TenantDropdown;
