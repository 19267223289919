import React from "react";
import { Loader, BasicContentSlider } from "smart-react";

/**
 * Used to populate the Slider Card Content based on the provided action
 */
const DataFieldContentSlider = ({ show, children }) => {
  return (
    <React.Suspense fallback={<Loader />}>
      <BasicContentSlider show={show} children={children} />
    </React.Suspense>
  );
};

export default DataFieldContentSlider;
