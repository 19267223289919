import React from 'react';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Label } from '@progress/kendo-react-labels';
import { Avatar } from '@progress/kendo-react-layout';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faUser,
  } from "@fortawesome/free-solid-svg-icons";

import avatarIcon from "../../../../assets/images/avatar-2.svg";

export const Upload = () => {

    return (
        <FieldWrapper>
                  <div className="profile-detail-image">
                <img src={avatarIcon} alt="User Profile" />
              </div>
        </FieldWrapper>
    );
};
export default Upload;